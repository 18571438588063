
























import { Vue, Component } from 'vue-property-decorator'
import { Modal, Steps, HttpGet, HttpPost, Loader, Card, PostButton, Action, PostActionButton } from '../../../lib/mavejs'
import { url } from '@/functions'
import { initialSearchForm } from '@/store/campaigns'
import UrlCheck from '@/components/campaigns/form/UrlCheck.vue'
import SearchCampaignForm from '@/views/campaigns/searchForm/SearchCampaignForm.vue'
import SearchTargetingForm from '@/views/campaigns/searchForm/SearchTargetingForm.vue'
import SearchGeoForm from '@/views/campaigns/searchForm/SearchGeoForm.vue'
import SearchOptionsForm from '@/views/campaigns/searchForm/SearchOptionsForm.vue'
import SearchSettingsForm from '@/views/campaigns/searchForm/SearchSettingsForm.vue'
import SearchAdsForm from '@/views/campaigns/searchForm/SearchAdsForm.vue'

@Component({
  components: { UrlCheck, Modal, Steps, HttpGet, HttpPost, Loader, Card, PostButton, Action, PostActionButton },
  methods: { url }
})
export default class EditSearchCampaign extends Vue {
  steps = [
    { icon: 'analytics', label: 'Campaign', component: SearchCampaignForm },
    { icon: 'people_alt', label: 'Targeting', component: SearchTargetingForm },
    { icon: 'flag', label: 'Geo\'s', component: SearchGeoForm },
    { icon: 'post_add', label: 'Options', component: SearchOptionsForm },
    { icon: 'featured_video', label: 'Ads', component: SearchAdsForm }
  ]

  isFetching = false
  show = false

  $refs: {
    submit: HttpPost;
    check: UrlCheck;
  }

  mounted () {
    if (this.$store.state.campaigns.form.searchEdit[this.$route.params.id] === undefined) {
      this.$store.state.campaigns.form.searchEdit[this.$route.params.id] = initialSearchForm
    }

    this.show = true
  }

  submit () {
    this.$refs.submit.post()
      .then(() => {
        this.$store.commit('clearForm', { name: 'searchEdit', id: this.$route.params.id })
        this.$router.push('/campaigns?refresh=true')
      })
  }
}
